import React, { Component } from 'react';
import { Container, Jumbotron, Button } from 'react-bootstrap';


class HomeFantalaser extends Component {


  render() {
    return (
      <div className="container-home-wrapper">
        <Container className="container-home">

            <h1>Benvenuto su Fantalaser!</h1>
            <h3 className="text-thin mt-3">Il primo Fantagame basato sul modo della vela.</h3>
            <p className="mt-5"><Button className="px-5" variant="warning" size="lg" onClick={ () => this.props.auth.login() } >Gioca!</Button></p>


        </Container>
      </div>
    );
  }
}

export default HomeFantalaser;
