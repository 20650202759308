import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { getAllTeams, getAdminConfig, getRegattaResults, getTeamMembersByRegattaAndFantalaserId, getFantalasersResults, addFantalaserResult} from '../../queries';
import { Container, Row, Col, Button, Card , Badge } from 'react-bootstrap';
import Spinner from '../Spinner';

import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import gql from 'graphql-tag';
import moment from "moment";

import _ from 'lodash';


function teamStatusFormatter(cell, row) {
  console.log('cellrow',cell,row);

  if(cell.length === 6){
    return <Badge variant="success">Completo</Badge>;
  }else{
    return <Badge variant="warning">Incompleto ({cell.length})</Badge>;
  }
}

const columnsAllTeams = [{
  dataField: 'team_id',
  text: 'ID',
  sort: true
},{
  dataField: 'team_name',
  text: 'Name',
  sort: true
},{
  dataField: 'user_email',
  text: 'Email',
  sort: true
}, {
  dataField: 'points',
  text: 'Points',
  sort: true
}, {
  dataField: 'laserists',
  text: 'N° Membri',
  formatter: teamStatusFormatter
} ];


class Admin extends Component {
  constructor(props) {
      super(props);

      this.state = {
        teams: [],
        adminConfig: {}
      };
  }


  saveWinnersDb(){

    let position = 1;
    this.state.teams.map( (team, index) => {


      // FULL TEAM -> ENTER IN DB
      if(team.laserists.length == 6){


        this.props.client
          .mutate({
            mutation: addFantalaserResult,
            variables: {
              fantalaserId: this.state.adminConfig.current_fantalaser_id,
              regattaId: this.state.adminConfig.current_regatta_id,
              teamId: team.team_id,
              points: team.points,
              position: position
            }
          })
          .then((data) => {
            console.log('ADDED TO DB', team, position, 'regatta:', this.state.adminConfig.current_regatta_id);

          })
          .catch(error => {
            console.error(error);
          });

          position++;


      }else {
        //alert('check console');
        console.error('team error', team);
      }



    });
  }

  generateWinners() {
    let teams = [];


    if (this.props.client) {

      /* STEP 1 - get admin configs */
      this.props.client
        .query({
          query: getAdminConfig,
        })
        .then((data) => {
          console.log('admin configs', data);

          const adminConfig = data.data.admin_configs[0];

          this.setState({ adminConfig: adminConfig })

          /* STEP 2 - get all regatta results for regatta id, fantalaser id  */

              this.props.client
                .query({
                  query: getRegattaResults,
                  variables: {
                    fantalaserId: adminConfig.current_fantalaser_id,
                    regattaId: adminConfig.current_regatta_id
                  }
                })
                .then((data) => {
                  console.log('regattas_results', data.data.ragattas_results);
                  this.setState({ regattas_results: data.data.ragattas_results })

                  const regattaResults = data.data.ragattas_results.reduce(function(obj,item){
                    obj[item.laserist_id] = item.fanta_points;
                    return obj;
                  }, {});

                  console.log('REGATTARESULTSARRAY', regattaResults);

                  /* STEP 3 - get all teams for fantalaser id, regatta id  */
                      this.props.client
                        .query({
                          query: getTeamMembersByRegattaAndFantalaserId,
                          variables: {
                            fantalaserId: adminConfig.current_fantalaser_id,
                            regattaId: adminConfig.current_regatta_id
                          }
                        })
                        .then((data) => {
                          console.log('teams_members', data.data.teams_members);
                          this.setState({ regattas_results: data.data.teams_members })

                          const teamMembers = data.data.teams_members;

                          /* STEP 4 - Aggregate teams by team_id   */

                          //TODO add team_name
                          let teamsAggregated =_.chain(teamMembers).groupBy("team_id").map(function(v, i) {
                            console.log('vi', v, i);
                            return {
                              team_id: i,
                              team_name: v[0].teamDetails.name,
                              user_email: v[0].teamDetails.email,
                              laserists: _.map(v, 'laserist_id')
                            }
                          }).value();

                          console.log('teamsAggregated', teamsAggregated);



                          /* STEP 5 - Generate total points for each team */

                          _.forEach(teamsAggregated, function(team) {

                            team.points = 0;
                            _.forEach(team.laserists, function(laserist) {
                              console.log('laserist', laserist);
                              team.points += (regattaResults[laserist])?regattaResults[laserist]: 0;
                            });

                            console.log('team', team);
                          });


                          teamsAggregated = _.orderBy(teamsAggregated, ['points'],['desc']);

                          this.setState({ teams: teamsAggregated });
                          console.log('teamsAggregated', teamsAggregated);



                            /* END STEP 6 */

                          /* END STEP 5 */

                          /* END STEP 4 */

                        })
                        .catch(error => {
                          console.error(error);
                        });




                  /* END STEP 3 */

                })
                .catch(error => {
                  console.error(error);
                });




          /* END STEP 2 */

        })
        .catch(error => {
          console.error(error);
        });
        /* END STEP 1 */
    }
  }



    render() {

      /*
                 */
        return (
        <Query query={getAllTeams}>
          {({ loading: loadingLaserists, error: errorLaserists, data: { teams } }) => {

                if (loadingLaserists) return (<Spinner />);
                if (errorLaserists) return (<h2>Error!</h2>);

console.log('this.state.teams',this.state.teams);
                return (<div>

                    <Container className="py-4">

                    <h1 className="text-light mb-4">Admin </h1>


                    <Card>
                    <Card.Body>
                    <p><Button variant="primary" size="large" onClick={ ()=> this.generateWinners() }>Genera Vincitori</Button></p>
                    <p><Button variant="danger" size="large" onClick={ ()=> this.saveWinnersDb() }>Salva Vincitori</Button></p>




                        <BootstrapTable
                          id="tableLaseristsList"
                          bootstrap4={true}
                          selectRow={{
                            mode: 'radio',
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => { this.addMember(row, isSelect, rowIndex, e); }
                          }}
                          classes='table-responsive-sm'
                          keyField='team_id'
                          data={ this.state.teams }
                          columns={ columnsAllTeams }
                          striped
                          hover
                          bordered={ false }
                           />

                             </Card.Body>
                           </Card>
                      </Container>
                      </div>
                );
          }}
        </Query>
        );
    }
}

export default Admin;
