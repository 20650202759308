import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { getAdminConfig, getFantalasersResults, getTeamLaseristsOlympicsById } from '../../queries';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import Spinner from '../Spinner';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


const columnsSingleTeam = [{
  dataField: 'memberDetails.name',
  text: 'Nome',
},{
  dataField: 'memberDetails.club',
  text: 'Nazionalità',
},{
  dataField: 'memberDetails.category',
  text: 'Categoria',
},{
  dataField: 'memberDetails.name',
  text: 'Crew1',
},{
  dataField: 'memberDetails.name_secondary',
  text: 'Crew2',
},{
  dataField: 'memberDetails.credits',
  text: 'Credits',
} ];



// points

// position
// n_velico
// category
// club
// fanta_score_position

class ModalSingleTeam extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        laserists: []
      };
  }

  loadTeamStats(teamId, fantalaserId, regattaId) {
    console.log('loadTeamStats', teamId, fantalaserId, regattaId);

    if (this.props.client) {

      /* STEP 1 - get team members */
      this.props.client
        .query({
          query: getTeamLaseristsOlympicsById,
          variables: {
            fantalaserId: fantalaserId,
            regattaId: regattaId,
            teamId: teamId
          }
        })
        .then((data) => {
          const teamMembers = data.data.olympics_teams_members;
          console.log('TEAM MEMEBERS', teamMembers);

          this.setState({
            laserists: teamMembers
          });



        })
        .catch(error => {
          console.error(error);
        });
        /* END STEP 1 */
    }


  }



  render() {
console.log('RENDER MODAL 2', this.props);

    if(!this.state.laserists.length ){
      if(this.props.fantalaser && this.props.regatta &&  this.props.team){
        console.log('LOADD', this.props);
        this.loadTeamStats(this.props.team, this.props.fantalaser, this.props.regatta);
      }
    }


/*       */

    return (
      <Modal size="sm" {...this.props} aria-labelledby="contained-modal" onExit={ () => this.setState({ laserists: [] }) }>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal">
            {this.props.teamname}
          </Modal.Title>
        </Modal.Header>
          <div>

          <BootstrapTable
                   id="tablelaseristsolympics"
                   bootstrap4={true}
                   classes='table-responsive'
                   keyField='memberDetails.id'
                   data={ this.state.laserists }
                   columns={ columnsSingleTeam }
                   striped
                   bordered={ false }
                    />

          </div>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Chiudi</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalSingleTeam;
