import React, { Component } from 'react';
import { Container, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import logo from '../images/logo.png';
import "../styles/App.scss";
import "react-toastify/dist/ReactToastify.css";

import MaterialIcon from 'material-icons-react';

class Header extends Component {
  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentDidMount() {
    const { renewSession } = this.props.auth;

    //console.log('AUTH', this.props.auth);

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }


  }

  render() {
    const { isAuthenticated } = this.props.auth;
    let isAdmin = false;

    /* subdomain check fantalaser/fantaolympics */
    let fantaolympics = false;
    let subdomain = window.location.hostname.split('.')[0];
    if( subdomain === 'fantaolympics') fantaolympics = true;
    let siteBrandName = (fantaolympics)? ' FantaOlympics' : ' Fantalaser';



    //console.log('IS AUTH', this.props.auth.userProfile);

    if(this.props.auth.userProfile){
      const role = this.props.auth.userProfile['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
      if(role[1] === 'admin' ) {
        isAdmin = true;
      }
    }


    let userName = 'Profile';
    if(this.props.auth.userProfile) {
      userName = this.props.auth.userProfile.nickname ? this.props.auth.userProfile.nickname : this.props.auth.userProfile.email;
    }

    return (
      <div>

      <Navbar collapseOnSelect bg="primary" variant="dark" expand="lg" fixed="top" >
        <Container>
          <Navbar.Brand onClick={this.goTo.bind(this, 'home')}><img
        alt=""
        src={ logo }
        width="56"
        className="d-inline-block align-top"
      />{ siteBrandName }</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="ml-auto">

            { isAdmin && (
            <NavDropdown title="Admin" id="basic-nav-dropdown">
              <NavDropdown.Item href="#" onClick={this.goTo.bind(this, 'admin')}>Admin Fantalaser</NavDropdown.Item>
              <NavDropdown.Item href="#" ></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#" onClick={this.goTo.bind(this, 'admin-olympics')}>Admin FantaOlympics</NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={this.goTo.bind(this, 'team-olympics')}>My Team Olympics</NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={this.goTo.bind(this, 'rankings-olympics')}>Classifiche Olmypics</NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={this.goTo.bind(this, 'olympics-contenders')}>Olympics Contenders</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#" onClick={this.goTo.bind(this, 'statistics')}>Statistics</NavDropdown.Item>
            </NavDropdown>
            ) }

              {!isAuthenticated() && ( <Nav.Link href="#" onClick={this.goTo.bind(this, 'home')} >Home</Nav.Link>  )}
              <Nav.Link href="#" onClick={this.goTo.bind(this, 'rules')} >Regole</Nav.Link>
              <Nav.Link href="#" onClick={this.goTo.bind(this, 'rankings')} >Classifiche</Nav.Link>
              <Nav.Link href="#" onClick={this.goTo.bind(this, 'contenders')}>Tutti i team</Nav.Link>
              { isAuthenticated() && ( <Nav.Link href="#" onClick={this.goTo.bind(this, 'team')}>Mio team</Nav.Link>) }
              { isAuthenticated() && ( <Nav.Link href="#" onClick={this.goTo.bind(this, 'profile')}>Profilo</Nav.Link>) }
              {!isAuthenticated() && ( <Nav.Link href="#" onClick={this.login.bind(this)} >Accedi</Nav.Link> )}
            </Nav>

          </Navbar.Collapse>
        </Container>

      </Navbar>
      </div>
    );
  }
}

export default Header;
