let local = false;



/* subdomain check fantalaser/fantaolympics */
let subdomain = 'fantalaser';
let sub = window.location.hostname.split('.')[0];
if( sub === 'fantaolympics') subdomain = 'fantaolympics';

//localhost
let callbackUrl = ( local ) ? 'http://localhost:3000/callback' : 'https://'+ subdomain +'.cinghiare.it/callback';
let logoutRedirect = ( local ) ? 'http://localhost:3000/home' : 'https://'+ subdomain +'.cinghiare.it/home';


export const AUTH_CONFIG = {
  domain: 'dev-ed85lwqa.eu.auth0.com',
  clientId: 'dw32KezwmaYqCeCLdTf21D9aF84Oyn8P',
  callbackUrl: callbackUrl,
  logoutRedirect: logoutRedirect
}
