import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

import imgRules1 from '../../images/rules-1.png';
import imgRules2 from '../../images/rules-2.png';
import imgRules3 from '../../images/rules-3.png';

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

//import 'react-accessible-accordion/dist/minimal-example.css';
import 'react-accessible-accordion/dist/fancy-example.css';


class RulesFantalaser extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="py-4">

      <h1 className="mb-4 text-light">Regole</h1>




      <Card className="p-3">
      <Card.Body>
      <h3>Benvenuto su Fantalaser!</h3>
<p>Il primo Fantagame basato sulle statistiche delle regate Laser.</p>
<p>Ecco come funziona in 5 semplici punti:</p>

<h4>1. Squadra</h4>
<p>Hai 400 crediti Fantalaser per selezionare la tua squadra composta da: 2 4.7, 2 Radial e 2 Standard (oppure un 4.7 e un Radial F per il Campionato Giovanile).</p>

<h4>2. Crediti Fantalaser </h4>
<p>Ad ogni Laserista è associato un valore espresso in crediti Fantalaser. Tale valore può aumentare o diminuire nel corso della stagione a seconda delle prestazioni reali.</p>

<h4>3. Punteggi</h4>
<p>Gli elementi della tua Fanta Squadra ottengono un punteggio basato sulle posizioni reali registrate nella regata.</p>

<h4>4. Cambio Classe</h4>
<p>Se un Laserista partecipa in una classe diversa rispetto a quella in cui è stato scelto, il suo punteggio verrà calcolato nel punteggio totale della tua Fanta Squadra.</p>

<h4>5. Premi</h4>
<p>Grazie al nostro partner Negrinautica tutti gli utenti che hanno creato una squadra Fantalaser concorrono automaticamente al Campionato Generale e alla vittoria di fantastici premi!</p>


      <h2>REGOLAMENTO FANTALASER COMPLETO</h2>
      <p>Puoi giocare al Fantalaser durante tutto l’arco della stagione, dalla seconda regata di Italia Cup 2019 (Ragusa) fino all’ultima. Per qualsiasi dubbio consulta la relativa sezione del regolamento!</p>

      <Accordion>
        <AccordionItem>
            <AccordionItemTitle>
                <h3>1. Creazione Squadra</h3>
            </AccordionItemTitle>
            <AccordionItemBody>
            <h4>1.1 - Composizione</h4>

<p>Ogni Fanta Squadra è composta da 6 Laseristi, suddivisi come segue:</p>

<p>n. 2 4.7</p>
<p>n. 2 Radial</p>
<p>n. 2 Standard</p>
<p>Tutti gli elementi della tua Fanta Squadra ottengono punti (per maggiori dettagli vai alla sezione Punteggi).</p>

<h4>1.2 - Valore d’acquisto</h4>
<p>Ad ogni Laserista è associato un valore di acquisto, espresso in crediti. Al momento dell’iscrizione hai a disposizione 400 crediti per costruire la tua Fanta Squadra.</p>
<p>Il valore d’acquisto di ogni Laserista può aumentare o diminuire nel corso della stagione a seconda delle prestazioni reali del Laserista.</p>

<h4>1.3 - Laseristi dello stesso Club</h4>
<p>Il numero massimo di Laseristi dello stesso circolo che puoi selezionare è 2.</p>

<h4>1.4 - Campionato Italiano Giovanile (Fantalaser special edition)</h4>
<p>Durante la stagione del Fantalser è previsto il campionato italiano giovanile classi in singolo.</p>
<p>Per tale evento la composizione della Fanta Squadra sarà composta da 6 Laseristi, suddivisi come segue:</p>
<p>n. 2 4.7 M</p>
<p>n. 1 4.7 F</p>
<p>n. 2 Radial M</p>
<p>n. 1 Radial F</p>




            </AccordionItemBody>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemTitle>
                <h3>2. Calendario</h3>
            </AccordionItemTitle>
            <AccordionItemBody>
                <p>Il calendario Fantalaser è organizzato in Fanta Regate, le quali possono durare un numero variabile di giorni e includono una e una sola regata.</p>

                <p>08/03/2019 - 10/03/2019	Italia Cup Ragusa</p>
                <p>26/04/2019 - 28/04/2019	Italia Cup Livorno</p>
                <p>09/05/2019 - 12/05/2019	Europa Cup Torbole</p>
                <p>14/06/2019 - 16/06/2019	Italia Cup Civitanova</p>
                <p>08/08/2019 - 11/08/2019	Campionato di Distretto, Ostia</p>
                <p>08/11/2019 - 10/11/2019	Italia Cup, Da assegnare</p>

            </AccordionItemBody>
        </AccordionItem>


        <AccordionItem>
            <AccordionItemTitle>
                <h3>3. Punteggi</h3>
            </AccordionItemTitle>
            <AccordionItemBody>
            <p>Il punteggio di ogni Laserista tiene conto della prestazione personale di ciascuno. Viene calcolato come la somma dei seguenti valori:</p>

            <p>Bonus</p>
            <p><img src={imgRules1} className="img-fluid" /></p>

            <p>Malus</p>
            <p><img src={imgRules2} className="img-fluid" /></p>

            <h4>3.2 - Variazione valore giocatori e allenatori</h4>

<p>Il valore di ciascun Laserista varia dopo ogni Fanta Regata di gioco in base alla posizione ottenute nella regata reale precedente, secondo la seguente formula: </p>

<p><img src={imgRules3} className="img-fluid" /></p>

<p>Dove:</p>
<ul>
<li>QA è la quotazione attuale</li>
<li>QP è la quotazione precedente</li>
<li>NPart. è il numero di partecipanti della regata precedente</li>
<li>P è la posizione ottenuta nella regata precedente</li>
</ul>


<p>Il valore di un Laserista nel nostro Fantalaser può decrescere fino ad un minimo di 1 credito e crescere fino ad un massimo di 100.<br />
Se un Laserista è alla sua prima regata gli verrà attribuito una quotazione iniziale pari alla media delle quotazioni della propria categoria.</p>


</AccordionItemBody>
</AccordionItem>


<AccordionItem>
<AccordionItemTitle>
    <h3>4. Classifiche</h3>
</AccordionItemTitle>
<AccordionItemBody>

<p>Qualsiasi utente che iscrive una squadra al Fantalaser compete di diritto per le seguenti classifiche:</p>

<p>Classifica Fanta Regata: rappresenta la classifica stilata sulla base dei punteggi della singola Fanta Regata.</p>

<p>Classifica Generale: rappresenta la somma dei punteggi ottenuti dalla propria squadra in tutte le Fanta Regate del Fantalser.</p>

<p>Ciascuna di queste Classifiche prevede dei Premi per i vincitori, trovi tutti i dettagli nel paragrafo dedicato!</p>


<h4>4.1 - Parità.</h4>

<p>In caso di parità tra due o più squadre:</p>
<p>Classifica Fanta Regata: verrà considerato come primo criterio il maggior punteggio nella classifica del Campionato Generale, come secondo criterio la data di iscrizione (si classifica prima chi si è iscritto prima)</p>
<p>Classifica Generale: verrà considerato come unico criterio la data e orario di creazione della squadra (si classifica prima chi ha creato prima la squadra).</p>



</AccordionItemBody>
</AccordionItem>


<AccordionItem>
<AccordionItemTitle>
    <h3>5. Gestione Squadra</h3>
</AccordionItemTitle>
<AccordionItemBody>

<p>Al termine di ogni regata le squadre vengono resettate e il numero di crediti torna a 400. Dovrai quindi ricreare la tua squadra di 6 Laseristi in vista della regata successiva.</p>

<p>All&rsquo;interno della sezione My Team puoi gestire la tua Fanta Squadra e potrai effettuare le seguenti operazioni:</p>


<ul>
<li><b>sostituzioni:</b> prima dell’inizio di una Fanta Regata e l’altra è possibile effettuare un numero illimitato di trasferimenti acquistando nuovi giocatori sul mercato. Per fare posto ai nuovi arrivati è prima necessario tagliare un giocatore dalla propria squadra, recuperando immediatamente un ammontare di crediti pari al suo costo.</li>
<li><b>consegna formazione:</b> la formazione va salvata entro i 60’ successivi all’inizio, previsto dal bando, della prima prova della regata reale. In caso di mancato salvataggio della formazione, viene considerata una formazione con punteggio 0.</li>
</ul>



</AccordionItemBody>
</AccordionItem>


<AccordionItem>
<AccordionItemTitle>
    <h3>6. Premi</h3>
</AccordionItemTitle>
<AccordionItemBody>


<p>Grazie alla partnership con Negrinautica, giocare al Fantalaser ti premia! Ogni squadra creata concorre automaticamente alla vittoria dei seguenti premi:</p>

<p><b>Premio di Fanta Regata:</b> il primo classificato di ogni Fanta Regata vincer&agrave; un salvagente NOB.</p>

<p><b>Premi per Classifica Generale:</b> <br />I primi 3 classificati del Campionato Generale, al termine del Torneo vinceranno:</p>


  <ul>
    <li>1&deg; classificato: Vela Laser;</li>
    <li>2&deg; classificato : Telo sopra Laser Negrinautica;</li>
    <li>3&deg; classificato: Borsa porta deriva e timone Laser Negrinautica.</li>
  </ul>


</AccordionItemBody>
</AccordionItem>


<AccordionItem>
<AccordionItemTitle>
    <h3>7. Contatti</h3>
</AccordionItemTitle>
<AccordionItemBody>


<p>Per qualsiasi domanda, dubbio o informazione per cui non trovi risposta qui sopra, potrai scrivere all&rsquo;indirizzo email <b>cinghiare@gmail.com</b> e ti risponderemo appena possibile.</p>

<p>Puoi trovarci anche su <a href="https://www.facebook.com/cinghiareselvatico/" target="_blank">Facebook</a> e <a href="https://www.instagram.com/cinghiare_selvatico/" target="_blank">Instagram</a>.</p>

</AccordionItemBody>
</AccordionItem>


<AccordionItem>
<AccordionItemTitle>
    <h3>8. Poteri dell{"'"}organizzazione</h3>
</AccordionItemTitle>
<AccordionItemBody>


<p>Cinghiare.it ha il potere di legiferare su qualunque aspetto del gioco, quali ad esempio: infrazioni o interpretazioni regolamentari, attribuzione di punteggi, annullamento giornate, recuperi e assegnazione dei premi, modifica di nomi offensivi o di cattivo gusto dati alle Fantasquadre.</p>

<p>Le decisioni di Cinghiare.it sono definitive e inappellabili. Qualora lo ritenesse necessario, Cinghiare.it ha il diritto di apportare qualunque modifica al gioco e alle regole. Qualsiasi modifica verr&agrave; tempestivamente comunicata tramite pop up all&rsquo;accesso sul sito o tramite newsletter.</p>

            </AccordionItemBody>
        </AccordionItem>



    </Accordion>








          </Card.Body>
          </Card>
      </Container>
    );
  }
}


export default RulesFantalaser;
