import gql from 'graphql-tag';





export const getFantalasersResults = gql `
query getFantalasersResults($fantalaserId: Int, $regattaId: Int) {
  fantalasers_results (where: {
    fantalaser_id: {_eq:$fantalaserId},
    regatta_id: {_eq:$regattaId},
  }){
    id
    team_id
    points
    position
    fantalaser_id
    regatta_id
    teamDetails {
      name
      nickname
    }
  }
}`;


export const getAllLaserists = gql`{
  laserists {
    id
    name
    category
    gender
    credits
    club
  }
}`;



export const getTeamLaseristsById = gql `
query getTeamLaseristsById($teamId: String, $fantalaserId: Int, $regattaId: Int) {
  teams_members (where: {
    team_id: {_eq:$teamId}
    fantalaser_id: {_eq:$fantalaserId},
    regatta_id: {_eq:$regattaId}
  }){
    id
    memberDetails {
      id
      name
      category
      gender
      credits
      club
    }
  }
}`;


export const getSingleRegattaResultsByLaserist = gql `
query getSingleRegattaResultsByLaserist($fantalaserId: Int, $regattaId: Int, $laseristId: Int) {
  ragattas_results (where: {
    laserist_id: {_eq:$laseristId}
    fantalaser_id: {_eq:$fantalaserId},
    regatta_id: {_eq:$regattaId}
  }){
    id
    regatta_id
    laserist_id
    fanta_points
    fantalaser_id
    score_1
    score_2
    score_3
    score_4
    score_5
    score_6
    score_7
    score_8
    score_9
    points
    score_f
    score_m
    score_u16
    score_u17
    score_u18
    score_u19
    score_u21
    score_master
    fanta_score_1
    fanta_score_2
    fanta_score_3
    fanta_score_4
    fanta_score_5
    fanta_score_6
    fanta_score_7
    fanta_score_8
    fanta_score_9
    fanta_score_m
    fanta_score_f
    fanta_score_u16
    fanta_score_u17
    fanta_score_u18
    fanta_score_u19
    fanta_score_u21
    fanta_score_master
    position
    n_velico
    category
    club
    fanta_score_position
    credits
    laseristDetails{
      name
      gender
    }
  }
}`;


export const getRegattaResults = gql `
query getRegattaResults($fantalaserId: Int, $regattaId: Int ) {
  ragattas_results (where: {
      fantalaser_id: {_eq: $fantalaserId },
      regatta_id: {_eq: $regattaId }
    }){
    id
    laserist_id
    fanta_points
  }
}`;


export const getTeamMembersByRegattaAndFantalaserId = gql `
query getTeamMembersByRegattaAndFantalaserId($fantalaserId: Int, $regattaId: Int ) {
  teams_members (where: {
    fantalaser_id: {_eq: $fantalaserId },
    regatta_id: {_eq: $regattaId }
  }){
    team_id
    laserist_id
    teamDetails {
      name
      email
      nickname
    }
  }
}`;

export const addFantalaserResult = gql`
  mutation($teamId: String!, $points: Int!, $position: Int!, $fantalaserId: Int!, $regattaId: Int!) {
  insert_fantalasers_results (
    objects: [
      {
        fantalaser_id: $fantalaserId,
        regatta_id: $regattaId,
        team_id: $teamId,
        points: $points,
        position: $position
      }
    ]
  ) {
    affected_rows
  }
}`;


export const updateTeamStatus = gql`
  mutation($teamId: Int!, $editable: Boolean! ) {
    update_teams(
      where: {id: {_eq: $teamId}},
      _set: {
        editable: $editable,
      }
    ) {
      affected_rows
    }
  }
`;




export const removeTeamMember = gql`
    mutation($teamMemberId: Int!) {
        delete_teams_members(
            where: {id: {_eq:$teamMemberId}}
        ){
            affected_rows
        }
    }
`;



export const addTeamMember = gql`
  mutation($teamId: String!, $laseristId: Int!, $fantalaserId: Int!, $regattaId: Int!) {
    insert_teams_members (
      objects: [
        {
          team_id: $teamId,
          laserist_id: $laseristId
          fantalaser_id: $fantalaserId
          regatta_id: $regattaId
        }
      ]
    ) {
      affected_rows
    }
  }
`;


export const getAllTeams = gql `{
  teams {
    id
    name
  }
}`;


export const getTeamDetails = gql `
query getTeamDetails($teamId: String) {
  teams (where: {id: {_eq:$teamId}}){
    id
    name
    editable
  }
}`;


export const updateTeamDetails = gql`
mutation($teamId: String!, $teamName: Int!, $email: String!, $nickname: String!, $realname: String! ) {
  insert_teams(
    objects: [
      {
        id: $teamId,
        name: $teamName,
        email: $email,
        nickname: $nickname,
        realname: $realname
      }
    ],
    on_conflict: {
      constraint: teams_pkey,
      update_columns: [name, email, nickname, realname]
    }
  ) {
    affected_rows
  }
}`;


export const getAdminConfig = gql `{
  admin_configs {
    allow_team_creation
    allow_team_creation_olympics
    current_fantalaser_id
    current_regatta_id
    current_fantalaser_id_olympics
    current_regatta_id_olympics
  }
}`;



/*
*
*
*

Olympics

*
*
*
***/

export const getAllLaseristsOlympics = gql`{
  olympics_laserists (where: {
    present: {_eq:true}
  }){
    id
    name
    name_secondary
    category
    credits
    club
  }
}`;


export const addTeamMemberOlympics = gql`
  mutation($teamId: String!, $laseristId: Int!, $fantalaserId: Int!, $regattaId: Int!) {
    insert_olympics_teams_members (
      objects: [
        {
          team_id: $teamId,
          laserist_id: $laseristId
          fantalaser_id: $fantalaserId
          regatta_id: $regattaId
        }
      ]
    ) {
      affected_rows
    }
  }
`;




export const getTeamLaseristsOlympicsById = gql `
query getTeamLaseristsById($teamId: String, $fantalaserId: Int, $regattaId: Int) {
  olympics_teams_members (where: {
    team_id: {_eq:$teamId}
    fantalaser_id: {_eq:$fantalaserId},
    regatta_id: {_eq:$regattaId}
  }){
    id
    memberDetails {
      id
      name
      name_secondary
      category
      credits
      club
    }
  }
}`;


export const removeTeamMemberOlympics = gql`
    mutation($teamMemberId: Int!) {
        delete_olympics_teams_members(
            where: {id: {_eq:$teamMemberId}}
        ){
            affected_rows
        }
    }
`;


export const getAllOlympicsTeamsByRegattaAndFantalaserId = gql `
query getAllOlympicsTeamsByRegattaAndFantalaserId($fantalaserId: Int, $regattaId: Int ) {
  olympics_teams_members (where: {
    fantalaser_id: {_eq: $fantalaserId },
    regatta_id: {_eq: $regattaId }
  }){
    team_id
    laserist_id
    teamDetails {
      name
      nickname
      email
    }
  }
}`;



/* Winners generation olympics */
export const getRegattaResultsOlympics = gql `
query getRegattaResults($fantalaserId: Int, $regattaId: Int ) {
  olympics_regattas_results (where: {
      fantalaser_id: {_eq: $fantalaserId },
      regatta_id: {_eq: $regattaId }
    }){
    id
    laserist_id
    fanta_points
  }
}`;




export const addFantalaserResultOlympics = gql`
  mutation($teamId: String!, $points: Int!, $position: Int!, $fantalaserId: Int!, $regattaId: Int!) {
  insert_olympics_fantalasers_results (
    objects: [
      {
        fantalaser_id: $fantalaserId,
        regatta_id: $regattaId,
        team_id: $teamId,
        points: $points,
        position: $position
      }
    ]
  ) {
    affected_rows
  }
}`;



export const getFantalasersResultsOlympics = gql `
query getFantalasersResultsOlympics($fantalaserId: Int, $regattaId: Int) {
  olympics_fantalasers_results (where: {
    fantalaser_id: {_eq:$fantalaserId},
    regatta_id: {_eq:$regattaId}
  }){
    id
    team_id
    points
    position
    fantalaser_id
    regatta_id
    teamDetails {
      name
      nickname
    }
  }
}`;





export const getSingleRegattaResultsByLaseristOlympics = gql `
query getSingleRegattaResultsByLaserist($fantalaserId: Int, $regattaId: Int, $laseristId: Int) {
  olympics_regattas_results (where: {
    laserist_id: {_eq:$laseristId}
    fantalaser_id: {_eq:$fantalaserId},
    regatta_id: {_eq:$regattaId}
  }){
    id
    regatta_id
    laserist_id
    fanta_points
    fantalaser_id
    score_1
    score_2
    score_3
    score_4
    score_5
    score_6
    score_7
    score_8
    score_9
    score_10
    score_11
    score_12
    score_13
    score_14
    score_15
    fanta_score_1
    fanta_score_2
    fanta_score_3
    fanta_score_4
    fanta_score_5
    fanta_score_6
    fanta_score_7
    fanta_score_8
    fanta_score_9
    fanta_score_10
    fanta_score_11
    fanta_score_12
    fanta_score_13
    fanta_score_14
    fanta_score_15
    position
    n_velico
    category
    club
    fanta_score_position
    credits
    name_secondary
    score_mr
    fanta_score_mr
    net_points
    total_points
    laseristDetails{
      name
      club
    }
  }
}`;



/* Get all regattas and fantalasers */

export const getAllRegattas = gql`{
  regattas (
    order_by: {date: asc}
  ) {
    id
    city
    date
    fantalaser_id
  }
}`;



export const getAllRegattasOlympics = gql`{
  olympics_regattas {
    id
    city
    date
    fantalaser_id
  }
}`;





/* TEMP HACK */


export const updateTeamDetailsHack = gql`
mutation($teamId: String!, $email: String!, $nickname: String! ) {
  update_teams(
    where: {id: {_eq: $teamId}},
    _set: {
        email: $email,
        nickname: $nickname,
      },
  ) {
    affected_rows
  }
}`;
