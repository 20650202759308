import React, { Component } from 'react';

import { Query, Mutation } from 'react-apollo';
import { getTeamLaseristsById, getTeamDetails, getAdminConfig, updateTeamStatus, addTeamMember, getAllLaserists } from '../../queries';
import { Container, Row, Col, Button, Alert, Navbar, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import RemoveTeamMember from './RemoveTeamMember';
import { ToastContainer, toast } from 'react-toastify';
import { Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import MaterialIcon from 'material-icons-react';

import { Redirect } from 'react-router-dom';
import Spinner from '../Spinner';


import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter, selectFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


const selectOptionsCategory = {
  'STD': 'STD',
  'RAD': 'RAD',
  '4.7': '4.7'
};

const selectOptionsGender = {
  'M': 'M',
  'F': 'F',
};

const columns = [{
  dataField: 'category',
  text: 'Cat',
  sort: true,
  formatter: cell => selectOptionsCategory[cell],
  filter: selectFilter({
    options: selectOptionsCategory,
  })
}, {
  dataField: 'gender',
  text: 'Sesso',
  sort: true,
  formatter: cell => selectOptionsGender[cell],
  filter: selectFilter({
    options: selectOptionsGender
  })
}, {
  sort: true,
  dataField: 'name',
  text: 'Nome',
  filter: textFilter()
},{
  sort: true,
  dataField: 'club',
  text: 'Club',
  filter: textFilter()
},{
  sort: true,
  dataField: 'credits',
  text: 'Crediti',
  filter: numberFilter({
    comparators: [ Comparator.GT, Comparator.LT],
    defaultValue: { comparator: Comparator.GT }
  })
}];


class TeamBuilder extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      isNavCreditsFixed: true,
      laserists: [],
      teamName: '',
      adminConfig: {},
      teamAddLoading: false
    };


  }

  checkTeamIsDefined() {
    const teamId = localStorage.getItem("sub");
    if (this.props.client) {
      this.props.client
        .query({
          query: getTeamDetails,
          variables: {
            teamId: teamId
          }
        })
        .then((data) => {
          console.log('OK', data);
          if( !data.data.teams.length ){
            console.log('TEAM NAME NOT DEFINED');
            return this.props.history.push('/profile');
          }
          this.setState({
            teamName: data.data.teams[0].name,
          })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }



  getAdminConfig() {
    if (this.props.client) {
      this.props.client
        .query({
          query: getAdminConfig,
        })
        .then((data) => {
          console.log('admin configs', data);
          this.setState({ adminConfig: data.data.admin_configs[0] })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }


  getAllLaserists() {
    if (this.props.client) {
      this.props.client
        .query({
          query: getAllLaserists,
        })
        .then((data) => {
          console.log('all laserists', data);
          this.setState({ laserists: data.data.laserists })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }




  componentWillUnmount() {
     this._isMounted = false;
     window.removeEventListener('scroll', this.scrollEvent.bind(this));
  }

  componentWillMount(){
    // check if user defined a team name
    console.log('COMPONENT WILL MOUNT');
    this.setState({ userProfile: this.props.auth.userProfile });

    this.checkTeamIsDefined();
    this.getAdminConfig();
    this.getAllLaserists();
  }

  componentDidMount(){
    this._isMounted = true;
    // check scroll for affix
    document.addEventListener('scroll', this.scrollEvent.bind(this) );
  }

  scrollEvent(){
    const isNavCreditsFixed = window.scrollY < 56;
    if(this._isMounted){
      if (isNavCreditsFixed !== this.state.isNavCreditsFixed) {
          this.setState({ isNavCreditsFixed })
      }
    }
  }


  scrollToLasristList() {
    scroller.scrollTo('listLaserists', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }




  addMember( laserist ) {
    const teamId = localStorage.getItem('sub');
    console.log('ADD_TEAM_MEMBER', laserist );

    // prevent double addition
    if(this.state.teamAddLoading) return;
    this.setState({ teamAddLoading: true});



    //get current team members and do checks
    this.props.client
      .query({
        query: getTeamLaseristsById,
        variables: {
          teamId,
          fantalaserId: this.state.adminConfig.current_fantalaser_id,
          regattaId: this.state.adminConfig.current_regatta_id
        }
      })
      .then((data) => {
        const members = data.data.teams_members;
        console.log('Members', members );


        setTimeout(
            function() {
                this.setState({ teamAddLoading: false});
            }
            .bind(this),
            500
        );




        //check if memeber already in team
        if(members.length > 0){
          if (members.filter(function(member) { return (member.memberDetails.id === laserist.id); }).length > 0) {
            return( toast.error("Già nel team!") );
          }
        }

        //check max 2 members per category
        let countStd = members.filter(item => item.memberDetails.category === "STD").length;
        let countRad = members.filter(item => item.memberDetails.category === "RAD").length;
        let countQs = members.filter(item => item.memberDetails.category === "4.7").length;

        if(
          (laserist.category === 'STD' && countStd === 2) ||
          (laserist.category === 'RAD' && countRad === 2 ) ||
          (laserist.category === '4.7' && countQs === 2)
        ){
          return( toast.error("Massimo 2 membri per categoria") );
        }



        // check max 2 members per club
        // let currentMemberClub = laserist.club;
        // let countAddedMembersCurrentClub = members.filter(item=> item.memberDetails.club === currentMemberClub).length;
        // if(countAddedMembersCurrentClub === 2){
        //   return( toast.error("Hai già 2 membri di questo club") );
        // }

        //check if exceeding total cost
        let currentTeamCost = members.reduce((prev,next) => prev + next.memberDetails.credits,0);
        if( currentTeamCost + laserist.credits > 400 ) {
          return( toast.error("Crediti insufficienti") );
        }


        //mutation add to teamMembers

        this.props.client
          .mutate({
            mutation: addTeamMember,
            variables: {
              teamId,
              laseristId: laserist.id,
              fantalaserId: this.state.adminConfig.current_fantalaser_id,
              regattaId: this.state.adminConfig.current_regatta_id
            }, refetchQueries: [{
              query: getTeamLaseristsById,
              variables: {
                teamId,
                fantalaserId: this.state.adminConfig.current_fantalaser_id,
                regattaId: this.state.adminConfig.current_regatta_id
              }
            }]
          })
          .then(() => {
            // handle response if required
            toast.success("Team Aggiornato e Salvato!");
          })
          .catch(error => {
            console.error(error);
          });



      })
      .catch(error => {
        console.error(error);
      });


  }





  render() {
    const { isAuthenticated } = this.props.auth;
    const teamId = localStorage.getItem('sub');

    return (
      <div>
        {
          isAuthenticated() && this.state.adminConfig && (

            <Query query={getTeamLaseristsById} variables={
              { teamId,
                fantalaserId: this.state.adminConfig.current_fantalaser_id,
                regattaId: this.state.adminConfig.current_regatta_id
              }
            }>
              {({ loading: loadingTeam, error: errorTeam, data }) => {
                if (loadingTeam) return (<Spinner />);
                if (errorTeam) return (<h2 className="text-light text-center mt-5">Error :-(</h2>);


                const members = data.teams_members;

                console.log('log', members );

                let std = members.filter(item => item.memberDetails.category === "STD") || [];
                let rad = members.filter(item => item.memberDetails.category === "RAD") || [];
                let qs = members.filter(item => item.memberDetails.category === "4.7") || [];
                let teamCost = members.reduce( function(cnt,o){ return cnt + o.memberDetails.credits; }, 0);

                //check team completed
                let teamReady = false;
                if(std.length === 2 && rad.length === 2 && qs.length === 2){
                  teamReady = true;
                }

                const adminAllowTeamEdit = this.state.adminConfig.allow_team_creation;


                console.log('adminAllowTeamEdit', adminAllowTeamEdit);

                return (
                    <div>
                        <Container className="py-4">

                        <h1 className="text-light">Team {this.state.teamName}</h1>

                        <Button className="d-md-none mt-4" variant="warning" size="lg" block onClick={ () => this.props.history.replace(`/rules`) }>Regole Fantalaser</Button>


                          {
                            // EDITABLE MODE
                            adminAllowTeamEdit && (
                              <Row className="my-4">
                              <Col md={4}>

                              <div className={ this.state.isNavCreditsFixed? '' : 'sticky-top' } >
                                <Alert className="pt-2" variant="warning">Crediti residui: {400 - teamCost}/400</Alert>

                              {
                                teamReady && (
                                  <div>
                                      <Alert className="pt-2" variant="success">
                                      <p className="mt-2 mb-0"><b>Team completo e salvato!</b><br/>
                                        { "Puoi ancora modificarlo fino a inizio regata." }
                                       </p>
                                      </Alert>
                                  </div>
                                )
                              }


                              <Row>
                                <Col md={12}>

                                <Card>



                                    <ListGroup variant="flush">
                                       <ListGroup.Item>
                                       <Card.Title>STD</Card.Title>
                                       <Card.Text>
                                         { !std[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()}><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                         { std[0] && (<RemoveTeamMember laserist={ std[0] } adminConfig={this.state.adminConfig} />) }

                                         { !std[1] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()}><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                         { std[1] && (<RemoveTeamMember laserist={ std[1] } adminConfig={this.state.adminConfig} />) }
                                       </Card.Text>
                                       </ListGroup.Item>


                                     <ListGroup.Item>
                                        <Card.Title>RAD</Card.Title>
                                        <Card.Text>
                                      { !rad[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()}><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                      { rad[0] && (<RemoveTeamMember adminConfig={this.state.adminConfig} laserist={ rad[0] } />) }

                                      { !rad[1] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()}><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                      { rad[1] && (<RemoveTeamMember adminConfig={this.state.adminConfig} laserist={ rad[1] } />) }
                                      </Card.Text>
                                      </ListGroup.Item>

                                      <ListGroup.Item>

                                        <Card.Title>4.7</Card.Title>
                                        <Card.Text>
                                      { !qs[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()}><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                      { qs[0] && (<RemoveTeamMember adminConfig={this.state.adminConfig} laserist={ qs[0] } />) }

                                      { !qs[1] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()}><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                      { qs[1] && (<RemoveTeamMember adminConfig={this.state.adminConfig} laserist={ qs[1] } />) }
                                      </Card.Text>
                                      </ListGroup.Item>
                                      </ListGroup>
                                  </Card>
                                </Col>

                                </Row>

                                </div>
                                </Col>



                                <Col md={8} className="mt-4 mt-md-0">
                                  <Element name="listLaserists" >

                                  <BootstrapTable
                                    id="tableLaseristsList"
                                    bootstrap4={true}
                                    selectRow={{
                                      mode: 'radio',
                                      clickToSelect: true,
                                      onSelect: (row, isSelect, rowIndex, e) => { this.addMember(row); }
                                    }}
                                    classes='table-responsive-sm'
                                    keyField='id'
                                    data={ this.state.laserists }
                                    columns={ columns }
                                    striped
                                    hover
                                    bordered={ false }
                                    pagination={ paginationFactory({
                                      sizePerPage: 25,
                                      sizePerPageList: [ {
                                          text: '25', value: 25
                                        }, {
                                          text: '50', value: 50
                                        }, {
                                          text: '100', value: 100
                                        }, {
                                          text: 'All', value: this.state.laserists.length
                                        } ]
                                    }) }
                                    filter={ filterFactory() }
                                     />


                                  </Element>
                                </Col>

                              </Row>

                            )
                          }





                          {
                            //PANORAMIC TEAM MODE - TEAM NOT EDITABLE
                            ( !adminAllowTeamEdit )  && (

                              <div className="mt-4">
                                {
                                  !adminAllowTeamEdit && (
                                    <p className="text-light">Mi dispiace le Regate sono già in corso, non é più possibile creare/modificare la propria formazione!</p>
                                  )
                                }


                                <Row>
                                  <Col md={4}>
                                    <Card className="mt-3">
                                      <Card.Body>
                                       <Card.Title>STD</Card.Title>
                                     </Card.Body>
                                     <ListGroup className="list-group-flush">
                                        <ListGroupItem>
                                          {std[0] ? std[0].memberDetails.name  : 'Vuoto'}
                                        </ListGroupItem>
                                        <ListGroupItem>
                                          { std[1] ? std[1].memberDetails.name  : 'Vuoto'}
                                        </ListGroupItem>
                                      </ListGroup>
                                     </Card>
                                   </Col>
                                   <Col md={4}>
                                     <Card className="mt-3">
                                       <Card.Body>
                                        <Card.Title>RAD</Card.Title>
                                      </Card.Body>
                                      <ListGroup className="list-group-flush">
                                         <ListGroupItem>
                                           { rad[0]?rad[0].memberDetails.name:'Vuoto' }
                                         </ListGroupItem>
                                         <ListGroupItem>
                                           { rad[1]?rad[1].memberDetails.name:'Vuoto' }
                                         </ListGroupItem>
                                       </ListGroup>
                                      </Card>
                                    </Col>
                                    <Col md={4}>
                                      <Card className="mt-3">
                                        <Card.Body>
                                         <Card.Title>4.7</Card.Title>
                                       </Card.Body>
                                       <ListGroup className="list-group-flush">
                                          <ListGroupItem>
                                            { qs[0]?qs[0].memberDetails.name: 'Vuoto' }
                                          </ListGroupItem>
                                          <ListGroupItem>
                                            { qs[1]?qs[1].memberDetails.name:'Vuoto' }
                                          </ListGroupItem>
                                        </ListGroup>
                                       </Card>
                                     </Col>
                                  </Row>


                              </div>

                            )
                          }








                        <ToastContainer autoClose={2000} position={toast.POSITION.BOTTOM_CENTER} />



                      </Container>
                    </div>
                );


              }}
            </Query>
          )
        }
        {
          !isAuthenticated() && (
            <Redirect to="/home"/>
          )
        }
      </div>
    );
  }
}


export default TeamBuilder;
