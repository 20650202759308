import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import logo_negrinautica from '../images/logo-negrinautica.jpg';


const Footer = props => {
  return (<div className="footer py-4 text-center bg-white mt-5"  >
  <Container>
    <Row>
      <Col md={2}>
        <a href="http://negrinautica.com" target="_blank"><img width="160" src={logo_negrinautica} /></a>
      </Col>
      <Col md={8}>
        <p>&copy; 2019 Fantalaser by Cinghiare.it</p>
      </Col>
      <Col md={2} className="d-none d-md-flex pt-2">
        <div className="float-right">
        <a href="https://www.iubenda.com/privacy-policy/42377549" className="iubenda-white iubenda-embed float-right" title="Privacy Policy">Privacy Policy</a>
        </div>
      </Col>
    </Row>
    </Container>
  </div>);
};

export default Footer;
