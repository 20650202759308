import React from 'react';
import { Route, Router, Redirect } from 'react-router-dom';
import Header from './components/App';
import Callback from './components/Callback/Callback';
import Auth from './components/Auth/Auth';
import history from './utils/history';

import Admin from './components/Admin/Admin';
import AdminOlympics from './components/AdminOlympics/AdminOlympics';
import HomeFantalaser from './components/Home/HomeFantalaser';
import HomeFantaolympics from './components/Home/HomeFantaolympics';
import RulesFantalaser from './components/Rules/RulesFantalaser';
import RulesFantaolympics from './components/Rules/RulesFantaolympics';
import Statistics from './components/Statistics/Statistics';

import FantalasersResults from './components/FantalasersResults/FantalasersResults';
import FantalasersResultsOlympics from './components/FantalasersResultsOlympics/FantalasersResultsOlympics';


import TeamBuilder from './components/TeamBuilder/TeamBuilder';
import TeamBuilderOlympics from './components/TeamBuilderOlympics/TeamBuilderOlympics';

import Contenders from './components/Contenders/Contenders';
import OlympicsContenders from './components/OlympicsContenders/OlympicsContenders';


import UserProfile from './components/UserProfile/UserProfile';

import Footer from './components/Footer';

import { ApolloProvider } from "react-apollo";
import makeApolloClient from "./apollo";

const client = makeApolloClient();

const provideClient = component => {
  return <ApolloProvider client={client}>{component}</ApolloProvider>;
};




const auth = new Auth();

const handleAuthentication = ({location}) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}


/* subdomain check fantalaser/fantaolympics */
let fantaolympics = false;
let subdomain = window.location.hostname.split('.')[0];
if( subdomain === 'fantaolympics') fantaolympics = true;


export const makeMainRoutes = () => {
  return (
      <Router history={history} >
        <div>
          <Route path="/" render={(props) => <Header auth={auth} {...props} />  } />
          <Route path="/rules" render={(props) => (
            (fantaolympics) ?
              ( <RulesFantaolympics auth={auth} {...props} /> ) :  ( <RulesFantalaser auth={auth} {...props} /> )
          )} />
          <Route path="/statistics" render={(props) => provideClient(<Statistics auth={auth} client={client}  {...props} />) } />





          <Route path="/rankings" render={(props) => (
            fantaolympics ? (
              <FantalasersResultsOlympics client={client}  auth={auth} {...props} />
            ) : (
              <FantalasersResults client={client}  auth={auth} {...props} />
            )
          )} />

          <Route path="/rankings-olympics" render={(props) => provideClient(
              <FantalasersResultsOlympics auth={auth} client={client} {...props} />
          )} />


          <Route path="/home" render={(props) => (
            fantaolympics ? (
              <HomeFantaolympics auth={auth} {...props} />
            ) : (
              <HomeFantalaser auth={auth} {...props} />
            )
          )} />


          <Route path="/profile" render={(props) => (
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (
              provideClient(<UserProfile auth={auth} client={client} {...props} />)
            )
          )} />





          <Route path="/contenders" render={(props) => provideClient(
            fantaolympics ?
              (<OlympicsContenders auth={auth} client={client} {...props} />)
                :
                (<Contenders auth={auth} client={client} {...props} />)
          )} />


          <Route path="/olympics-contenders" render={(props) => provideClient(
            <OlympicsContenders auth={auth} client={client} {...props} />
          )} />




          <Route path="/team" render={(props) => provideClient(
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (fantaolympics?
              (<TeamBuilderOlympics auth={auth} client={client} {...props} />)
                :
                (<TeamBuilder auth={auth} client={client} {...props} />)
          ))} />


          <Route path="/team-olympics" render={(props) => provideClient(
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (
              <TeamBuilderOlympics auth={auth} client={client} {...props} />
            )
          )} />



          <Route path="/admin" render={(props) => provideClient(
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (
              <Admin auth={auth} client={client} {...props} />
            )
          )} />


          <Route path="/admin-olympics" render={(props) => provideClient(
            !auth.isAuthenticated() ? (
              <Redirect to="/home"/>
            ) : (
              <AdminOlympics auth={auth} client={client} {...props} />
            )
          )} />

          <Route path="/callback" render={(props) => {
            handleAuthentication(props);
            return <Callback {...props} />
          }}/>


          <Footer />
        </div>

      </Router>
  );
}
