import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { removeTeamMemberOlympics, getTeamLaseristsOlympicsById } from '../../queries';
import { Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import MaterialIcon from 'material-icons-react';

/* NOT IN USE */

class RemoveTeamMemberOlympics extends Component {
    constructor(props) {
        super(props);
    }

    removeMember(remove_team_member) {
      console.log('Remove + refetch', this.props);
      const teamId = localStorage.getItem('sub');
      remove_team_member({ variables: { teamMemberId: this.props.laserist.id } , refetchQueries: [{ query: getTeamLaseristsOlympicsById, variables: { teamId, fantalaserId: this.props.adminConfig.current_fantalaser_id_olympics, regattaId: this.props.adminConfig.current_regatta_id_olympics} }] });
    }

    render() {
        return (
            <Mutation
              mutation={removeTeamMemberOlympics}
              onCompleted={({ login }) => {
                toast.success("Team Aggiornato e Salvato!");
              }}
            >
                {(remove_team_member, { data }) => (

                      <Button className="mt-2" block variant="danger" onClick={ () => this.removeMember(remove_team_member) } >
                        {this.props.laserist.memberDetails.name }<small>({this.props.laserist.memberDetails.credits })</small>
                         {this.props.laserist.memberDetails.name_secondary && (<div><small>{ this.props.laserist.memberDetails.name_secondary} </small></div> )}
                        <span className="float-right"><MaterialIcon icon="close" color="#FFF" /></span>
                      </Button>

                )}
            </Mutation>
        );
    }
}

export default RemoveTeamMemberOlympics;
