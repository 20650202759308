import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { getAllRegattasOlympics, getFantalasersResultsOlympics } from '../../queries';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import Spinner from '../Spinner';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ModalSingleTeamResults from './ModalSingleTeamResults';

import _ from 'lodash';

function btnOpen(cell, row) {
  return (
    <button className="btn btn-primary btn-sm"  >Classifica</button>
  );
}

/*
,{
  dataField: 'date',
  text: 'Data',
  sort: true
}*/

const columnsRegattas = [{
  dataField: 'city',
  text: 'Regata',
  sort: true
},{
  dataField: 'id',
  text: 'Seleziona',
  formatter: btnOpen,
  align: 'right',
  headerAlign: 'right'
} ];


const columnsWinners = [{
  dataField: 'position',
  text: 'Pos',
  sort: true
},{
  dataField: 'teamDetails.name',
  text: 'Team',
  sort: true
}, {
  dataField: 'points',
  text: 'Points',
  sort: true
}, {
  dataField: 'id',
  text: 'Visualizza',
  formatter: btnOpenModalFormatter,
  align: 'right',
  headerAlign: 'right'
} ];


let columnsfullResults = [{
  dataField: 'position',
  text: 'Pos',
  sort: true
},{
  dataField: 'teamDetails.name',
  text: 'Team',
  sort: true
}, {
  dataField: 'points_0',
  text: 'Points 0',
  sort: true
},{
  dataField: 'points_1',
  text: 'Points 1',
  sort: true
},{
  dataField: 'points_2',
  text: 'Points 2',
  sort: true
},{
  dataField: 'points_3',
  text: 'Points 3',
  sort: true
},{
  dataField: 'points_4',
  text: 'Points 4',
  sort: true
},{
  dataField: 'total',
  text: 'Tot',
  sort: true
}];



const defaultSorted = [{
  dataField: 'date',
  order: 'desc'
}];


const defaultSortedGeneral = [{
  dataField: 'total',
  order: 'desc'
}];


function btnOpenModalFormatter(cell, row) {
  return (
    <button className="btn btn-primary btn-sm"  >Visualizza</button>
  );
}



class FantalasersResultsOlympics extends Component {
  constructor(props) {
      super(props);

      this.state = {
        regattas: [],
        winners: [],
        isLoading: false,
        modalShow: false,
        activeRegatta: '',
        activeTeamId: 0,
        activeTeamName: '',
        activeFantalaserId: 0,
        activeRegattaId: 0,

        fullResults: []
      };



  }


  componentDidMount() {

    if (this.props.client) {
      this.props.client
        .query({
          query: getAllRegattasOlympics
        })
        .then((data) => {
          console.log('REGATTAS', data);
          this.setState({ regattas: data.data.olympics_regattas })
        }).catch(error => {
          console.error(error);
        });
    }
  }



  handleRowRegattasClick(row) {
    const regattaId = row.id;
    const fantalaserId = row.fantalaser_id;
    console.log('DATA', regattaId, fantalaserId);

    this.setState({ isLoading: true });

    this.props.client
      .query({
        query: getFantalasersResultsOlympics,
        variables: {
          fantalaserId: fantalaserId,
          regattaId: regattaId
        }
      })
      .then((data) => {
        console.log('FANTALASER RESULTS', data);
        this.setState({
          winners: data.data.olympics_fantalasers_results,
          activeRegatta: row.city,
          isLoading: false
        })

      }).catch(error => {
        console.error(error);
      });

  }



  generaClassificheGenerali(fantalaserId) {
    if(!fantalaserId) fantalaserId = 1;


    // 1. tutte le regate del fantalaser
    this.state.regattas.map(function(regatta, index){
      console.log('regatta', regatta);

      //update bootstra table cols
      if(index < this.state.regattas.length)
        columnsfullResults[index+2].text = regatta.city;



      // 2. tutte le classifiche di ogni regata
      if(this.props.client){
        this.props.client
          .query({
            query: getFantalasersResultsOlympics,
            variables: {
              fantalaserId: 1,
              regattaId: regatta.id
            }
          })
          .then((result) => {

            result = result.data.olympics_fantalasers_results;
            var data =  _.map(result, _.clone);

              console.log('FANTALASER RESULTS', data);



            if( data.length > 0 ) {

              console.log('LOOP n° '+index);

              data.forEach( function(res) {
                res = _.rename(res, 'points', 'points_'+index);
                //console.log('DATA2', res);
              });


              console.log('UNION BY TEAM_ID', data, this.state.fullResults);


              //_.unionBy(data, this.state.fullResults, 'team_id');

              var merged = _(data).concat(this.state.fullResults).groupBy('team_id').map(_.spread(_.assign)).value();

              _.forEach(merged, function(item) {

                var points_0 = item.points_0 || 0,
                    points_1 = item.points_1 || 0,
                    points_2 = item.points_2 || 0,
                    points_3 = item.points_3 || 0,
                    points_4 = item.points_4 || 0,
                    points_5 = item.points_5 || 0;

                    var total = points_0 + points_1 + points_2 + points_3 + points_4 + points_5;
                    item.total = total;

                console.log('iiii', item, total);
              });



              this.setState({
                fullResults: merged,
                winners: [],
                activeRegatta: ''
              });

              console.log('UNITED', merged);


            }

          }).catch(error => {
            console.error(error);
          });
      }



    }, this);


  }




  handleRowWinnersClick(row) {
    const teamId = row.team_id;
    const fantalaserId = row.fantalaser_id;
    const regattaId = row.regatta_id;
    const teamName = row.teamDetails.name;
    console.log(teamId, row);

    this.setState({
      modalShow: true ,
      activeTeamId: teamId,
      activeFantalaserId: fantalaserId,
      activeRegattaId: regattaId,
      activeTeamName: teamName
    });
  }



    render() {

      let modalClose = () => this.setState({ modalShow: false });

        return (
            <div>
                    <Container className="py-4">

                    <h1 className="text-light mb-4">Classifiche </h1>
                    <Row><Col md={4}>

                    <Card>
                    <Card.Body>
                        <BootstrapTable
                          id="tableRegattas"
                          bootstrap4={true}
                          selectRow={{
                            mode: 'radio',
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => { this.handleRowRegattasClick(row); }
                          }}
                          classes='table-responsive-lg'
                          keyField='date'
                          data={ this.state.regattas }
                          columns={ columnsRegattas }
                          striped
                          hover
                          bordered={ false }
                           />

                           <Button onClick={() => this.generaClassificheGenerali()} className="btn btn-block btn-primary btn-sm mt-2" >Classifica Generale</Button>


                             </Card.Body>
                           </Card>
                           </Col>
                           <Col>



                           { (this.state.isLoading) && (<Spinner /> ) }



                              {
                                (this.state.winners.length == 0) && (this.state.activeRegatta != '') && (
                                  <Card>
                                    <Card.Body>
                                      <h4 className="py-5 text-center">Classifica non ancora disponibile</h4>
                                    </Card.Body>
                                  </Card>
                                )
                              }

                              {
                                (this.state.winners.length) != 0 && (
                                  <Card>
                                    <Card.Body>
                                    <h4>{this.state.activeRegatta}</h4>
                                  <BootstrapTable
                                    id="tableWinners"
                                    bootstrap4={true}
                                    selectRow={{
                                      mode: 'radio',
                                      clickToSelect: true,
                                      onSelect: (row, isSelect, rowIndex, e) => { this.handleRowWinnersClick(row); }
                                    }}
                                    classes='table-responsive-lg'
                                    keyField='id'
                                    data={ this.state.winners }
                                    columns={ columnsWinners }
                                    striped
                                    hover
                                    bordered={ false }
                                    defaultSorted={ defaultSorted }
                                     />
                                     </Card.Body>
                                    </Card>
                                )
                              }



                              {
                                (this.state.fullResults.length) != 0 &&
                                (this.state.winners.length  == 0) &&
                                (this.state.activeRegatta == '') && (
                                  <Card>
                                    <Card.Body>
                                    <h4>Classifica Generale</h4>

                                  <BootstrapTable
                                    id="tableFullResults"
                                    bootstrap4={true}
                                    classes='table-responsive-lg'
                                    keyField='id'
                                    data={ this.state.fullResults }
                                    columns={ columnsfullResults }
                                    striped
                                    hover
                                    bordered={ false }
                                    defaultSorted={ defaultSortedGeneral }
                                     />
                                     </Card.Body>
                                    </Card>
                                )
                              }



                             </Col>
                             </Row>
                      </Container>



                      <ModalSingleTeamResults
                       size="lg"
                       client={this.props.client}
                       show={this.state.modalShow}
                       team={this.state.activeTeamId}
                       teamname={this.state.activeTeamName}
                       fantalaser={this.state.activeFantalaserId}
                       regatta={this.state.activeRegattaId}
                       onHide={modalClose} />


                      </div>

        );
    }
}



_.rename = (obj, key, newKey) => {
  if(_.includes(_.keys(obj), key)) {
    obj[newKey] = _.clone(obj[key], true)
    delete obj[key]
  }
  return obj
}



export default FantalasersResultsOlympics;
