import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { getAdminConfig, getFantalasersResults, getTeamLaseristsById, getSingleRegattaResultsByLaserist} from '../../queries';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import Spinner from '../Spinner';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


const columnsRegattasResults = [{
  dataField: 'id',
  text: 'id',
},{
  dataField: 'laseristDetails.name',
  text: 'nome',
},{
  dataField: 'category',
  text: 'cat',
},{
  dataField: 'club',
  text: 'club',
},{
  dataField: 'laseristDetails.gender',
  text: 'sesso',
},{
  dataField: 'credits',
  text: 'credits',
},{
  dataField: 'fanta_points',
  text: 'fanta_points',
},{
  dataField: 'fanta_score_1',
  text: 'fanta_score_1',
},{
  dataField: 'fanta_score_2',
  text: 'fanta_score_2',
}, {
  dataField: 'fanta_score_3',
  text: 'fanta_score_3',
}, {
  dataField: 'fanta_score_4',
  text: 'fanta_score_4',
}, {
  dataField: 'fanta_score_5',
  text: 'fanta_score_5',
}, {
  dataField: 'fanta_score_6',
  text: 'fanta_score_6',
}, {
  dataField: 'fanta_score_7',
  text: 'fanta_score_7',
}, {
  dataField: 'fanta_score_8',
  text: 'fanta_score_8',
}, {
  dataField: 'fanta_score_9',
  text: 'fanta_score_9',
},{
  dataField: 'fanta_score_position',
  text: 'fanta_score_position',
},{
  dataField: 'fanta_score_f',
  text: 'fanta_score_F',
},{
  dataField: 'fanta_score_m',
  text: 'fanta_score_M',
},{
  dataField: 'fanta_score_u16',
  text: 'fanta_score_u16',
},{
  dataField: 'fanta_score_u17',
  text: 'fanta_score_u17',
},{
  dataField: 'fanta_score_u18',
  text: 'fanta_score_u18',
},{
  dataField: 'fanta_score_u19',
  text: 'fanta_score_u19',
},{
  dataField: 'fanta_score_u21',
  text: 'score_u21',
},{
  dataField: 'fanta_score_master',
  text: 'fanta_score_master',
}  ];




class ModalSingleTeamResults extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        regattasResults: []
      };
  }

  loadTeamStats(teamId, fantalaserId, regattaId) {
    console.log('loadTeamStats', teamId, fantalaserId, regattaId);

    if (this.props.client) {

      /* STEP 1 - get team members */
      this.props.client
        .query({
          query: getTeamLaseristsById,
          variables: {
            fantalaserId: fantalaserId,
            regattaId: regattaId,
            teamId: teamId
          }
        })
        .then((data) => {
          const teamMembers = data.data.teams_members;
          console.log('TEAM MEMEBERS', teamMembers);




          // get regatta results for each laserist member of the team
          teamMembers.map( function(laserist){
            this.props.client
              .query({
                query: getSingleRegattaResultsByLaserist,
                variables: {
                  fantalaserId: fantalaserId,
                  regattaId: regattaId,
                  laseristId: laserist.memberDetails.id
                }
              })
              .then((data) => {
                let regattaResult = data.data.ragattas_results[0];

                if(!regattaResult){
                  regattaResult = {
                    id: laserist.id,
                    laseristDetails: laserist.memberDetails
                  }
                }

                console.log('REGATTA RESULTS', laserist, regattaResult);





                this.setState({
                  regattasResults: [...this.state.regattasResults, regattaResult]
                });

                console.log('THIS.STATE.REGATTARESULT', this.state.regattasResults);



              }).catch(error => {
                console.error(error);
              });
          }, this);





        })
        .catch(error => {
          console.error(error);
        });
        /* END STEP 1 */
    }


  }



  render() {
console.log('RENDER MODAL 2', this.props);

    if(!this.state.regattasResults.length ){
      if(this.props.fantalaser && this.props.regatta &&  this.props.team){
        console.log('LOADDDD', this.props);
        this.loadTeamStats(this.props.team, this.props.fantalaser, this.props.regatta);
      }
    }


/*       */

    return (
      <Modal {...this.props} aria-labelledby="contained-modal-title-vcenter" onExit={ () => this.setState({ regattasResults: [] }) }>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.teamname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <BootstrapTable
                   id="tableRegattasResults"
                   bootstrap4={true}
                   classes='table-responsive'
                   keyField='id'
                   data={ this.state.regattasResults }
                   columns={ columnsRegattasResults }
                   striped
                   hover
                   bordered={ false }
                    />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalSingleTeamResults;
