import React, { Component } from 'react';

import { Query, Mutation } from 'react-apollo';
import { getTeamLaseristsOlympicsById, getTeamDetails, getAllLaseristsOlympics, getAdminConfig, updateTeamStatus, addTeamMemberOlympics, updateTeamDetailsHack } from '../../queries';
import { Container, Row, Col, Button, Alert, Navbar, Card, ListGroup, ListGroupItem, Image } from 'react-bootstrap';
import RemoveTeamMemberOlympics from './RemoveTeamMemberOlympics';
import { ToastContainer, toast } from 'react-toastify';
import MaterialIcon from 'material-icons-react';

import { Redirect } from 'react-router-dom';
import Spinner from '../Spinner';

import { Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';


import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter, selectFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


import imgRules1 from '../../images/rules-1.png';



const selectOptionsCategory = {
  'STANDARD': 'STANDARD',
  'RADIAL': 'RADIAL',
  '470 M': '470 M',
  '470 W': '470 W',
  '49ER': '49ER',
  '49ER FX': '49ER FX',
  'NACRA': 'NACRA',
  'RS:X M': 'RS:X M',
  'RS:X W': 'RS:X W',
  'FINN': 'FINN'
};



const selectOptionsGender = {
  'M': 'M',
  'F': 'F',
};

function classCellFormatter(cell, row) {
  return (
    <Image
      src={process.env.PUBLIC_URL + '/images/classes/' + cell.replace(/\s/g, '') + '.png'}
      width="48"
      roundedCircle
      />
  );
}


function flagCellFormatter(cell, row) {
  return (
    <Image
      src={process.env.PUBLIC_URL + '/images/flags/' + cell + '.png'}
      width="25"
      rounded
      />
  );
}

const columns = [{
  dataField: 'category',
  text: 'Classe',
  sort: true,
  formatter: cell => selectOptionsCategory[cell],
  formatter: classCellFormatter,
  filter: selectFilter({
    options: selectOptionsCategory,
  })
}, {
  sort: true,
  dataField: 'name',
  text: 'Crew1',
  filter: textFilter()
},{
  dataField: 'name_secondary',
  text: 'Crew2',
  sort: true,
  filter: textFilter()
},{
  sort: true,
  dataField: 'club',
  text: 'Nazione',
  formatter: flagCellFormatter,
  filter: textFilter()
},{
  sort: true,
  dataField: 'credits',
  text: 'Crediti',
  filter: numberFilter({
    comparators: [ Comparator.GT, Comparator.LT],
    defaultValue: { comparator: Comparator.GT }
  })
}];





class TeamBuilderOlympics extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      olympics_laserists: [],
      isNavCreditsFixed: true,
      teamName: '',
      adminConfig: {},
      teamEditable: true,
      teamAddLoading: false
    };

  }

  componentWillMount(){
    // check if user defined a team name
    console.log('COMPONENT WILL MOUNT');
    this.setState({ userProfile: this.props.auth.userProfile });

    this.checkTeamIsDefined();
    this.getAdminConfig();
    this.getAllLaserists();
  }

  componentDidMount(){
    this._isMounted = true;
    document.addEventListener('scroll', this.scrollEvent.bind(this) );
  }

  scrollEvent(){
    const isNavCreditsFixed = window.scrollY < 56;

    console.log('asd');
    if(this._isMounted){
      if (isNavCreditsFixed !== this.state.isNavCreditsFixed) {
          this.setState({ isNavCreditsFixed })
      }
    }
  }


  checkTeamIsDefined() {
    const teamId = localStorage.getItem("sub");
    if (this.props.client) {
      this.props.client
        .query({
          query: getTeamDetails,
          variables: {
            teamId: teamId
          }
        })
        .then((data) => {
          console.log('OK', data);
          if( !data.data.teams.length ){
            console.log('TEAM NAME NOT DEFINED');
            return this.props.history.push('/profile');
          }

          /* TEMP HACK SAVE NICKNAME and other stuff in teams */

          console.log('HACK UPDATE NICKNAME', teamId, this.state.userProfile );

          this.props.client
            .mutate({
              mutation: updateTeamDetailsHack,
              variables: {
                teamId,
                nickname: this.state.userProfile.nickname,
                email: this.state.userProfile.email
              }
            })
            .then(() => {
              // handle response if required
              console.log('user updated hack', teamId );
            })
            .catch(error => {
              console.error('err user hack' , error);
            });


          /* END TEMP HACK */


          this.setState({
            teamName: data.data.teams[0].name,
            teamEditable: data.data.teams[0].editable
          })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }



  getAdminConfig() {
    if (this.props.client) {
      this.props.client
        .query({
          query: getAdminConfig,
        })
        .then((data) => {
          console.log('admin configs', data);
          this.setState({ adminConfig: data.data.admin_configs[0] })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }




  getAllLaserists() {
    if (this.props.client) {
      this.props.client
        .query({
          query: getAllLaseristsOlympics,
        })
        .then((data) => {
          console.log('all laserists', data);
          this.setState({ olympics_laserists: data.data.olympics_laserists })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }




  addMember( laserist ) {
    const teamId = localStorage.getItem('sub');
    console.log('ADD_TEAM_MEMBER', laserist );

    // prevent double addition
    if(this.state.teamAddLoading) return;
    this.setState({ teamAddLoading: true});



    //get current team members and do checks
    this.props.client
      .query({
        query: getTeamLaseristsOlympicsById,
        variables: {
          teamId,
          fantalaserId: this.state.adminConfig.current_fantalaser_id_olympics,
          regattaId: this.state.adminConfig.current_regatta_id_olympics
        }
      })
      .then((data) => {
        const members = data.data.olympics_teams_members;
        console.log('Members', members );


        setTimeout(
            function() {
                this.setState({ teamAddLoading: false});
            }
            .bind(this),
            500
        );




        //check if memeber already in team
        if(members.length > 0){
          if (members.filter(function(member) { return (member.memberDetails.id === laserist.id); }).length > 0) {
            return( toast.error("Già nel team!") );
          }
        }


        //check if already 1 member present
        let countStandard = members.filter(item => item.memberDetails.category === "STANDARD").length;
        let countRadial = members.filter(item => item.memberDetails.category === "RADIAL").length;
        let countQsm = members.filter(item => item.memberDetails.category === "470 M").length;
        let countQsw = members.filter(item => item.memberDetails.category === "470 W").length;
        let countQner = members.filter(item => item.memberDetails.category === "49ER").length;
        let countQnerfx  = members.filter(item => item.memberDetails.category === "49ER FX").length;
        let countNacra  = members.filter(item => item.memberDetails.category === "NACRA").length;
        let countRsxm = members.filter(item => item.memberDetails.category === "RS:X M").length;
        let countRsxw = members.filter(item => item.memberDetails.category === "RS:X W").length;
        let countFinn = members.filter(item => item.memberDetails.category === "FINN").length;

        if(
          (laserist.category === 'STANDARD' && countStandard === 1) ||
          (laserist.category === 'RADIAL' && countRadial === 1 ) ||
          (laserist.category === '470 M' && countQsm === 1) ||
          (laserist.category === '470 W' && countQsw === 1 ) ||
          (laserist.category === '49ER' && countQner === 1 ) ||
          (laserist.category === '49ER FX' && countQnerfx === 1 ) ||
          (laserist.category === 'NACRA' && countNacra === 1 ) ||
          (laserist.category === 'RS:X M' && countRsxm === 1 ) ||
          (laserist.category === 'RS:X W' && countRsxw === 1 ) ||
          (laserist.category === 'FINN' && countFinn === 1 )
        ){
          return( toast.error("Hai già un membro in questa categoria") );
        }


        //console.info('counts', countStandard, countRadial, countQsm, countQsw, countQner, countQnerfx, countNacra, countRsxm, countRsxw, countFinn);



        // check maximum 2 memebers per club
        let currentMemberClub = laserist.club;
        let countAddedMembersCurrentClub = members.filter(item=> item.memberDetails.club === currentMemberClub).length;
        // if(countAddedMembersCurrentClub === 2){
        //   return( toast.error("Hai già 2 membri di questa nazionalità") );
        // }

        //check if exceeding total cost
        let currentTeamCost = members.reduce((prev,next) => prev + next.memberDetails.credits,0);
        if( currentTeamCost + laserist.credits > 400 ) {
          return( toast.error("Crediti insufficienti") );
        }


      //  console.log('INSERT_TEAM_MEMBER' );



        //mutation add to teamMembers

        this.props.client
          .mutate({
            mutation: addTeamMemberOlympics,
            variables: {
              teamId,
              laseristId: laserist.id,
              fantalaserId: this.state.adminConfig.current_fantalaser_id_olympics,
              regattaId: this.state.adminConfig.current_regatta_id_olympics
            }, refetchQueries: [{
              query: getTeamLaseristsOlympicsById,
              variables: {
                teamId,
                fantalaserId: this.state.adminConfig.current_fantalaser_id_olympics,
                regattaId: this.state.adminConfig.current_regatta_id_olympics
              }
            }]
          })
          .then(() => {
            // handle response if required
            toast.success("Team Aggiornato e Salvato!");
          })
          .catch(error => {
            console.error(error);
          });



      })
      .catch(error => {
        console.error(error);
      });


  }



  scrollToLasristList() {
    scroller.scrollTo('listLaserists', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }







  render() {
    const { isAuthenticated } = this.props.auth;
    const teamId = localStorage.getItem('sub');

    return (
      <div>
        {
          isAuthenticated() && this.state.adminConfig && (

            <Query query={getTeamLaseristsOlympicsById} variables={
              { teamId,
                fantalaserId: this.state.adminConfig.current_fantalaser_id_olympics,
                regattaId: this.state.adminConfig.current_regatta_id_olympics
              }
            }>
              {({ loading: loadingTeam, error: errorTeam, data }) => {
                if (loadingTeam) return (<Spinner />);
                if (errorTeam) return (<h2 className="text-light text-center mt-5">Error :-(</h2>);


                const members = data.olympics_teams_members;
                console.log('log', members );

                let standard = members.filter(item => item.memberDetails.category === "STANDARD") || [];
                let radial = members.filter(item => item.memberDetails.category === "RADIAL") || [];
                let qsm = members.filter(item => item.memberDetails.category === "470 M") || [];
                let qsw = members.filter(item => item.memberDetails.category === "470 W") || [];
                let qner = members.filter(item => item.memberDetails.category === "49ER") || [];
                let qnerfx  = members.filter(item => item.memberDetails.category === "49ER FX") || [];
                let nacra  = members.filter(item => item.memberDetails.category === "NACRA") || [];
                let rsxm = members.filter(item => item.memberDetails.category === "RS:X M") || [];
                let rsxw = members.filter(item => item.memberDetails.category === "RS:X W") || [];
                let finn = members.filter(item => item.memberDetails.category === "FINN") || [];

                let teamCost = members.reduce( function(cnt,o){ return cnt + o.memberDetails.credits; }, 0);

                  //check team completed
                let teamReady = false;
                if(standard.length === 1 && radial.length === 1 && qsm.length === 1
                    && qsw.length === 1 && qner.length === 1 && qnerfx.length === 1
                    && nacra.length === 1 && rsxm.length === 1 && rsxw.length === 1
                    && finn.length === 1 ){
                  teamReady = true;
                }

                const adminAllowTeamEdit = this.state.adminConfig.allow_team_creation_olympics;
                console.log('adminAllowTeamEditOlympics', adminAllowTeamEdit);

                return (
                    <div>
                        <Container className="py-4">

                        <h1 className="text-light">Team {this.state.teamName}</h1>

                        <Button className="d-md-none mt-4 mb-2" variant="warning" size="lg" block onClick={ () => this.props.history.replace(`/rules`) }>Regole Fantalaser</Button>

                          {
                            // EDITABLE MODE
                            this.state.teamEditable && adminAllowTeamEdit && (
                              <div>

                              <div className={ this.state.isNavCreditsFixed? '' : 'sticky-top sticky-top-olympics' } >
                                <Alert className="pt-2" variant="warning">Crediti Residui: {teamCost}/400</Alert>
                              </div>


                              <Row className="my-4">

                              <Col md={12}>

                              <div >


                              {
                                teamReady && (
                                  <div>
                                      <Alert className="pt-2" variant="success">
                                      <p className="mt-2 mb-0"><b>Team completo e salvato!</b><br/>

                                        { "Puoi ancora modificarlo fino a inizio regata." }
                                       </p>
                                      </Alert>
                                  </div>
                                )
                              }


                              <Row>
                                <Col md={12}>

                                <Card className="container-fanta-teams">
                                  <Card.Body>
                                    <Row>
                                      <Col md={3}>
                                       <h4>STANDARD</h4>
                                       { !standard[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { standard[0] && (<RemoveTeamMemberOlympics laserist={ standard[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>RADIAL</h4>
                                       { !radial[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { radial[0] && (<RemoveTeamMemberOlympics laserist={ radial[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col >
                                      <Col  md={3}>
                                       <h4>470 M</h4>
                                       { !qsm[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { qsm[0] && (<RemoveTeamMemberOlympics laserist={ qsm[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>470 W</h4>
                                       { !qsw[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { qsw[0] && (<RemoveTeamMemberOlympics laserist={ qsw[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>49ER</h4>
                                       { !qner[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { qner[0] && (<RemoveTeamMemberOlympics laserist={ qner[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>49ER FX</h4>
                                       { !qnerfx[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { qnerfx[0] && (<RemoveTeamMemberOlympics laserist={ qnerfx[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>NACRA</h4>
                                       { !nacra[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { nacra[0] && (<RemoveTeamMemberOlympics laserist={ nacra[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>RS:X M</h4>
                                       { !rsxm[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { rsxm[0] && (<RemoveTeamMemberOlympics laserist={ rsxm[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>RS:X W</h4>
                                       { !rsxw[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { rsxw[0] && (<RemoveTeamMemberOlympics laserist={ rsxw[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                      <Col  md={3}>
                                       <h4>FINN</h4>
                                       { !finn[0] && (<Button variant="secondary" block onClick={() => this.scrollToLasristList()} ><MaterialIcon icon="person_add" color="#FFF" /></Button> ) }
                                       { finn[0] && (<RemoveTeamMemberOlympics laserist={ finn[0] } adminConfig={this.state.adminConfig} />) }
                                      </Col>
                                    </Row>
                                    </Card.Body>
                                </Card>

                                </Col>

                                </Row>

                                </div>
                                </Col>


                                {
                                  !teamReady && (
                                <Col md={12} className="mt-4">

                                <Element name="listLaserists" ></Element>

                                <Card>

                                    <BootstrapTable
                                      id="tableLaseristsList"
                                      bootstrap4={true}
                                      selectRow={{
                                        mode: 'radio',
                                        clickToSelect: true,
                                        onSelect: (row, isSelect, rowIndex, e) => { this.addMember(row); }
                                      }}
                                      classes='table-responsive-sm'
                                      keyField='id'
                                      data={ this.state.olympics_laserists }
                                      columns={ columns }
                                      striped
                                      hover
                                      bordered={ false }
                                      pagination={ paginationFactory({
                                        sizePerPage: 25,
                                        sizePerPageList: [ {
                                            text: '25', value: 25
                                          }, {
                                            text: '50', value: 50
                                          }, {
                                            text: '100', value: 100
                                          }, {
                                            text: 'All', value: this.state.olympics_laserists.length
                                          } ]
                                      }) }
                                      filter={ filterFactory() }
                                       />

                                </Card>


                                </Col>

                              )}

                              </Row>
                              </div>
                            )
                          }





                          {
                            //PANORAMIC TEAM MODE - TEAM NOT EDITABLE
                            (!this.state.teamEditable  ||  !adminAllowTeamEdit )  && (

                              <div className="mt-4">
                                {
                                  !adminAllowTeamEdit && (
                                    <div>
                                      <p className="text-light">Mi dispiace le Regate sono già in corso, non é più possibile creare/modificare la propria formazione!</p>
                                    </div>
                                  )
                                }


                                  {
                                    adminAllowTeamEdit && (
                                      <Mutation mutation={ updateTeamStatus } onCompleted={() => { this.setState({ teamEditable: true});  }}>
                                        {(setStatus, { data }) => (
                                          <div className="text-center my-5">
                                              <Button size="lg" variant="warning" onClick={ () => setStatus({ variables: { teamId: teamId, editable: true } }) }> Modifica Team </Button>
                                          </div>
                                        )}
                                      </Mutation>
                                    )
                                  }

                              </div>

                            )
                          }








                        <ToastContainer autoClose={2000} position={toast.POSITION.BOTTOM_CENTER} />



                      </Container>
                    </div>
                );


              }}
            </Query>
          )
        }
        {
          !isAuthenticated() && (
            <Redirect to="/home"/>
          )
        }
      </div>
    );
  }
}


export default TeamBuilderOlympics;
