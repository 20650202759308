import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { getAdminConfig, getTeamMembersByRegattaAndFantalaserId  } from '../../queries';
import { Container, Row, Col, Button, Card, Modal, Badge } from 'react-bootstrap';
import Spinner from '../Spinner';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ModalSingleTeam from './ModalSingleTeam';
import _ from 'lodash';


function btnOpenModalFormatter(cell, row) {
  return (
    <button className="btn btn-primary btn-sm"  >Visualizza</button>
  );
}


function teamStatusFormatter(cell, row) {
  console.log('cellrow',cell,row);

  if(cell.length === 6){
    return <Badge variant="success">Completo</Badge>;
  }else{
    return <Badge variant="warning">Incompleto ({cell.length})</Badge>;
  }
}


const columnsContenders = [{
  dataField: 'team_name',
  text: 'Team',
  sort: true
},{
  dataField: 'user_nickname',
  text: 'Utente',
  sort: true
},{
  dataField: 'laserists',
  text: 'Stato',
  formatter: teamStatusFormatter,
},{
  dataField: 'team_id',
  text: 'Visualizza',
  formatter: btnOpenModalFormatter,
  align: 'right',
  headerAlign: 'right'
}];


class Contenders extends Component {
  constructor(props) {
      super(props);

      this.state = {
        contenders: [],
        adminConfig: {},
        modalShow: false,
        activeTeamId: 0,
        activeTeamName: ''
      };
  }


  componentDidMount() {

    if (this.props.client) {

      /* STEP 1 - get admin configs */
      this.props.client
        .query({
          query: getAdminConfig,
        })
        .then((data) => {
          console.log('admin configs', data);
          const adminConfig = data.data.admin_configs[0];
          this.setState({ adminConfig })


          this.props.client
            .query({
              query: getTeamMembersByRegattaAndFantalaserId,
              variables: {
                fantalaserId: adminConfig.current_fantalaser_id,
                regattaId: adminConfig.current_regatta_id
              }
            })
            .then((data) => {
              console.log('CONTENDER_TEAMS', data);

              let teamMembers = data.data.teams_members;

              let teamsAggregated =_.chain(teamMembers).groupBy("team_id").map(function(v, i) {
                console.log('vi', v, i);
                return {
                  team_id: i,
                  team_name: v[0].teamDetails.name,
                  user_nickname: v[0].teamDetails.nickname,
                  laserists: _.map(v, 'laserist_id')
                }
              }).value();

              this.setState({ contenders: teamsAggregated })

              console.log('teamsAggregated', teamMembers, teamsAggregated);



            }).catch(error => {
              console.error(error);
            });


        })
        .catch(error => {
          console.error(error);
        });
        /* END STEP 1 */
    }
  }



  handleRowClick(row) {
    //console.log('ROWW', row);
    const teamId = row.team_id;
    const teamName = row.team_name;

    this.setState({
      modalShow: true ,
      activeTeamId: teamId,
      activeTeamName: teamName
    });
  }


    render() {
      let modalClose = () => this.setState({ modalShow: false });


        return (
<div>
                    <Container className="py-4">

                    <h1 className="text-light mb-4">Tutti i Team </h1>


                    <Card>
                    <Card.Body>
                        <BootstrapTable
                          id="tableLaseristsList"
                          bootstrap4={true}
                          selectRow={{
                            mode: 'radio',
                            clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => { this.handleRowClick(row); }
                          }}
                          classes='table-responsive-sm'
                          keyField='team_id'
                          data={ this.state.contenders }
                          columns={ columnsContenders }
                          striped
                          hover
                          bordered={ false }
                           />

                             </Card.Body>
                           </Card>
                      </Container>


                       <ModalSingleTeam
                        size="lg"
                        client={this.props.client}
                        show={this.state.modalShow}
                        team={this.state.activeTeamId}
                        teamname={this.state.activeTeamName}
                        fantalaser={this.state.adminConfig.current_fantalaser_id}
                        regatta={this.state.adminConfig.current_regatta_id}
                        onHide={modalClose} />
</div>
        );
    }
}

export default Contenders;
