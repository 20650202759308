import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { getAllLaserists } from '../../queries';
import { Container } from 'react-bootstrap';
import Spinner from '../Spinner';

import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


import gql from 'graphql-tag';
import moment from "moment";


const selectOptionsCategory = {
  'STD': 'STD',
  'RAD': 'RAD',
  '4.7': '4.7'
};

const selectOptionsGender = {
  'M': 'M',
  'F': 'F',
};

const columns = [{
  dataField: 'category',
  text: 'Cat',
  sort: true,
  formatter: cell => selectOptionsCategory[cell],
  filter: selectFilter({
    options: selectOptionsCategory,
  })
}, {
  dataField: 'gender',
  text: 'M/F',
  sort: true,
  formatter: cell => selectOptionsGender[cell],
  filter: selectFilter({
    options: selectOptionsGender
  })
}, {
  sort: true,
  dataField: 'name',
  text: 'Laserist',
  filter: textFilter()
},{
  sort: true,
  dataField: 'club',
  text: 'Club',
  filter: textFilter()
}];


class Statistics extends Component {
  constructor(props) {
      super(props);

  }



    selectLaserist() {
      console.log('SELECT_LASERIST');
    }


    updateLastSeen = () => {
      const userId = localStorage.getItem("auth0:id_token:sub");
      const timestamp = moment().format();
      if (this.props.client) {
        this.props.client
          .mutate({
            mutation: gql`
              mutation($userId: String!, $timestamp: timestamptz!) {
                update_users(
                  where: { auth0_id: { _eq: $userId } }
                  _set: { auth0_id: $userId, last_seen: $timestamp }
                ) {
                  affected_rows
                }
              }
            `,
            variables: {
              userId: userId,
              timestamp: timestamp
            }
          })
          .then(() => {
            // handle response if required
          })
          .catch(error => {
            console.error(error);
          });
      }
    }




    render() {
        return (
        <Query query={getAllLaserists}>
          {({ loading: loadingLaserists, error: errorLaserists, data: { laserists } }) => {

                if (loadingLaserists) return (<Spinner />);
                if (errorLaserists) return (<h2>Error!</h2>);

                return (
                    <Container className="py-4">

                    <div className="text-light">
                      <h1>Statistics </h1>
                      <p>Select a player to show stats:</p>
                    </div>

                    <BootstrapTable
                      id="tableLaseristsList"
                      bootstrap4={true}
                      selectRow={{
                        mode: 'radio',
                        clickToSelect: true,
                        onSelect: (row, isSelect, rowIndex, e) => { console.log(''); }
                      }}
                      classes='table-responsive-sm'
                      keyField='id'
                      data={ laserists }
                      columns={ columns }
                      striped
                      hover
                      bordered={ false }
                      pagination={ paginationFactory({
                        sizePerPage: 25,
                        sizePerPageList: [ {
                            text: '25', value: 25
                          }, {
                            text: '50', value: 50
                          }, {
                            text: '100', value: 100
                          }, {
                            text: 'All', value: laserists.length
                          } ]
                      }) }
                      filter={ filterFactory() }
                       />
                      </Container>
                );
          }}
        </Query>
        );
    }
}

export default Statistics;
