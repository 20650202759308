import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { getTeamDetails, updateTeamDetails } from '../../queries';
import { Container, Row, Col, Form,  Button, Alert, Navbar, Card, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import MaterialIcon from 'material-icons-react';

import Spinner from '../Spinner';
import { Mutation } from 'react-apollo';



class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userProfile: {},
      teamName: '',
      isFirstTime: false
    };
  }


  componentWillMount(){
    this.setState({ userProfile: this.props.auth.userProfile });
    // get team details
    this.getTeamDetails();
  }


  getTeamDetails() {
    const teamId = localStorage.getItem("sub");
    if (this.props.client) {
      this.props.client
        .query({
          query: getTeamDetails,
          variables: {
            teamId: teamId,
          }
        })
        .then((data) => {
          console.log('OK', data);
          if( !data.data.teams.length ){
            this.setState({ isFirstTime: true });
          }
          this.setState({teamName: data.data.teams[0].name });
        })
        .catch(error => {
          console.log('err',error);
        });
    }
  }







  handleTeamNameChange(e) {
    this.setState({teamName: e.target.value });
  }




  render() {
    const { isAuthenticated } = this.props.auth;
    const teamId = localStorage.getItem('sub');

    const editTeamMutation = () => <Button />;


    return (
      <Container className="py-4 mb-5">

        {
          isAuthenticated() && this.state.isFirstTime && (
            <Row>
              <Col  md={{ span: 6, offset: 3 }}>
                <Card className="my-5">
                  <Card.Body>
                    <Card.Title>Benvenuto su Fantalaser!</Card.Title>
                    <Card.Text>
                      <p>Per iniziare, dai un nome al tuo team:</p>

                      <Mutation
                        mutation={ updateTeamDetails }
                        onCompleted={ function(data){
                          window.location.reload();
                        }}
                        onError={ function(data){
                          console.log('ERROR', data);
                          toast.error("Team name already in use");
                        }}

                         >
                       {(update_team_details, { data, loading, error }) => (


                         <Form onSubmit={e => {
                           e.preventDefault();

                           if(this.state.teamName.length <3 ){
                             return toast.error("Team name too short");
                           }

                           if(this.state.teamName.length > 20 ){
                             return toast.error("Team name too long");
                           }

                           update_team_details({ variables: {
                             teamId: teamId,
                             teamName: this.state.teamName,
                             email: this.state.userProfile.email,
                             nickname: this.state.userProfile.nickname,
                             realname: this.state.userProfile.name
                           }, refetchQueries: [{ query: getTeamDetails }]  });

                         }}>
                         <Form.Group controlId="teamName" >
                           <Form.Control type="text" placeholder="Nome Team" value={ this.state.teamName } onChange={ this.handleTeamNameChange.bind(this) } />
                         </Form.Group>

                         <Button variant="primary" block type="submit">{ "Andiamo!" }</Button>
                         </Form>


                       )}
                     </Mutation>

                    </Card.Text>
                  </Card.Body>
                </Card>

              </Col>
           </Row>
          )
        }

        {
          isAuthenticated() && !this.state.isFirstTime && (
            <div>
            <h1 className="text-light mb-4">Profilo </h1>

            <Row>
              <Col md={6}>
              <Card className="mt-3">
              <Card.Body>
                <h2>Info</h2>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control disabled type="email" placeholder={ this.state.userProfile.email } />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>


                </Form>
                </Card.Body>
                </Card>



              </Col>


              <Col md={6}>
              <Card className="mt-3">
              <Card.Body>

                <h2>Team</h2>

                <Mutation
                  mutation={ updateTeamDetails }
                  onCompleted={ function(data){
                    window.location.reload();
                  }}
                  onError={ function(data){
                    toast.error("Team name already in use");
                  }}

                   >
                 {(update_team_details, { data, loading, error }) => (


                   <Form onSubmit={e => {
                     e.preventDefault();

                     if(this.state.teamName.length <3 ){
                       return toast.error("Team name too short");
                     }

                     if(this.state.teamName.length > 20 ){
                       return toast.error("Team name too long");
                     }


                     //console.log('USER PROFILE DATA', this.state.userProfile.email, this.state.userProfile.name, this.state.userProfile.nickname);


                     update_team_details({ variables: {
                       teamId: teamId,
                       teamName: this.state.teamName,
                       email: this.state.userProfile.email,
                       nickname: this.state.userProfile.nickname,
                       realname: this.state.userProfile.name
                     }, refetchQueries: [{ query: getTeamDetails }]  });

                   }}>
                   <Form.Group controlId="teamName" >
                    <Form.Label>Team Name</Form.Label>
                     <Form.Control type="text" placeholder="Insert your team name" value={ this.state.teamName } onChange={ this.handleTeamNameChange.bind(this) } />
                   </Form.Group>

                   <Button variant="primary" type="submit">Salva</Button>
                   </Form>


                 )}
               </Mutation>



                </Card.Body>
                </Card>


                <Card className="mt-3">
                  <Card.Body>
                    <Button variant="danger" block onClick={ ()=> this.props.auth.logout() }>Esci</Button>
                  </Card.Body>
                </Card>


              </Col>

              </Row>


              </div>
          )
        }

        <ToastContainer autoClose={2000} position={toast.POSITION.BOTTOM_CENTER} />
      </Container>
    );
  }
}


export default UserProfile;
