import React from 'react';
import MDSpinner from 'react-md-spinner';
import { Container } from 'react-bootstrap';


const Spinner = props => {
  return (<Container className="my-5 pt-5 text-center">
              <MDSpinner singleColor="#fff" size={ 48 } />
          </Container>);
};

export default Spinner;
